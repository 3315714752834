import { query, collection, where, getDocs, addDoc, onSnapshot } from 'firebase/firestore';
import { auth, firestore } from '../../../firebaseConfig';

export const fetchStripeProducts = async () => {
  const db = firestore;

  // Create a query object
  const q = query(collection(db, 'products'), where('active', '==', true));
  const querySnapshot = await getDocs(q);

  // For each product, get the product price info
  const productsPromises = querySnapshot.docs.map(async (productDoc) => {
    let productInfo = productDoc.data();

    // Fetch prices subcollection per product
    const pricesCollection = collection(productDoc.ref, 'prices');
    const priceQuerySnapshot = await getDocs(pricesCollection);

    // Assume there is only one price per product
    const priceDoc = priceQuerySnapshot.docs[0];
    productInfo['priceId'] = priceDoc.id;
    productInfo['priceInfo'] = priceDoc.data();
    return productInfo;
  });

  // 'products' is an array of products (including price info)
  const products = await Promise.all(productsPromises);
  console.log(products); // Log the products to verify the price IDs
  return products;
};

export const createStripeCheckoutSession = async (priceId, isOneTime) => {
  const user = auth.currentUser;
  if (!user) {
    console.error('User not authenticated');
    return;
  }

  const checkoutSessionData = {
    price: priceId,
    success_url: window.location.origin, // Set this to a custom page if needed
    cancel_url: window.location.origin,   // Set this to a custom page if needed
  };

  // If payment is a one-time payment (as opposed to subscription)
  if (isOneTime) {
    checkoutSessionData['mode'] = 'payment';
  }

  const checkoutSessionRef = await addDoc(
    collection(firestore, `customers/${user.uid}/checkout_sessions`),
    checkoutSessionData
  );

  // The Stripe extension creates a payment link for us
  onSnapshot(checkoutSessionRef, (snap) => {
    const data = snap.data();
    if (data?.error) {
      // Handle error
      console.error("Error creating checkout session: ", data.error);
    }
    if (data?.url) {
      window.location.assign(data.url);  // Redirect to payment link
    }
  });
};
