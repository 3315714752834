
function SideFeaturesToggle({ visibleFeatures, toggleFeature }) {
  const features = [
    { key: 'philtrumFlatness', label: 'Philtrum Flatness' },
    { key: 'leftGonialAngle', label: 'Left Gonial Angle' },
    { key: 'nasofrontalAngle', label: 'Nasofrontal Angle' },
    { key: 'nasalAngle', label: 'Nasal Angle' },
    { key: 'nasolabialAngle', label: 'Nasolabial Angle' },
    { key: 'nasalProjection', label: 'Nasal Projection' },
    { key: 'totalFacialConvexity', label: 'Total Facial Convexity' },
    { key: 'facialConvexity', label: 'Facial Convexity' },
    { key: 'mandibularPlaneAngle', label: 'Mandibular Plane Angle' },
  ];

  return (
    <div>
      {features.map((feature) => (
        <div
          key={feature.key}
          onClick={() => toggleFeature(feature.key)}
          style={{
            cursor: 'pointer',
            padding: '10px',
            margin: '5px 0',
            borderRadius: '10px',
            backgroundColor: visibleFeatures[feature.key] ? 'lightblue' : 'white',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
          }}
        >
          {feature.label}
        </div>
      ))}
    </div>
  );
}

export default SideFeaturesToggle;