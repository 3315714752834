// src/components/AuthListener.js
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../firebaseConfig';

const AuthListener = ({ setUser }) => {
  const navigate = useNavigate();
  const [initialAuthChecked, setInitialAuthChecked] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      if (user && !initialAuthChecked) {
        navigate('/upload');
        setInitialAuthChecked(true);
      }
    });

    return () => unsubscribe();
  }, [navigate, setUser, initialAuthChecked]);

  return null;
};

export default AuthListener;
