import React, { useState } from 'react';
import { signInWithPopup, GoogleAuthProvider, signInWithEmailAndPassword } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { auth } from '../../../firebaseConfig';
import colors from '../../styles/colors';
import { useLocation } from 'react-router-dom';
import googleSignIn from '../../../assets/images/google_sign_in.png';
import { sendEmailVerification } from 'firebase/auth';
import VerifyEmailModal from './VerifyEmailModal';

function SignUpModal({ isOpen, onClose, onLoginClick }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [inputReferralCode, setInputReferralCode] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // State to manage loading indicator
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const { state } = useLocation();
  const functions = getFunctions();

  if (!isOpen) return null;

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }
    if (password.length < 6) {
      setError('Password must be at least 6 characters long.');
      return;
    }
    setLoading(true); // Start loading
    try {
      const signUpUser = httpsCallable(functions, 'signUpUser');
      await signUpUser({ email, password, inputReferralCode, scanData: state?.data });
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      const actionCodeSettings = {
        url: 'https://goldenratioai.com',
        handleCodeInApp: true,
      };

      await sendEmailVerification(user, actionCodeSettings);
      setShowVerifyModal(true);
      setError('');
    } catch (error) {
      if (error && typeof error === 'object' && 'code' in error) {
        if (error.code === 'functions/already-exists') {
          setError('An account with this email already exists.');
        } else {
          console.error('Error signing up:', error);
          setError('Failed to sign up. Please check your email and password.');
        }
      } else {
        console.error('Unknown error signing up:', error);
        setError('An unexpected error occurred.');
      }
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleGoogleSignUp = async () => {
    const provider = new GoogleAuthProvider();
    try {
      setLoading(true); // Start loading
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const googleSignUpUser = httpsCallable(functions, 'googleSignUpUser');
      await googleSignUpUser({
        email: user.email,
        uid: user.uid,
        inputReferralCode,
        scanData: state?.data,
      });
      onClose();
    } catch (error) {
      console.error('Error with Google sign up:', error);
      setError('Failed to sign up with Google.');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <>
      <div style={modalOverlayStyles}>
        <div style={modalStyles}>
          <button onClick={onClose} style={closeButtonStyles}>×</button>
          <h2>Sign Up</h2>
          <p>Find your best features by signing up today!</p>
          <p>Sign up for 1 free credit</p>
          <div style={formStyles}>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={inputStyles}
              disabled={loading}
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={inputStyles}
              disabled={loading}
            />
            <input
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              style={inputStyles}
              disabled={loading}
            />
            <input
              type="text"
              placeholder="Referral Code (Optional)"
              value={inputReferralCode}
              onChange={(e) => setInputReferralCode(e.target.value)}
              style={inputStyles}
              disabled={loading}
            />
            <button onClick={handleSignUp} style={buttonStyles} disabled={loading}>
              {loading ? 'Signing Up...' : 'Sign Up'}
            </button>
            <button onClick={handleGoogleSignUp} style={googleButtonWrapperStyles} disabled={loading}>
              <img
                src={googleSignIn}
                alt="Sign up with Google"
                style={googleButtonStyles}
              />
            </button>
            <p>
              Already have an account? <span style={linkStyles} onClick={onLoginClick}>Log in here</span>
            </p>
          </div>
        </div>
      </div>
      <VerifyEmailModal isOpen={showVerifyModal} onClose={() => setShowVerifyModal(false)} />
    </>
  );
}

const modalOverlayStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 999,
};

const modalStyles = {
  position: 'relative',
  backgroundColor: colors.background,
  padding: '20px',
  borderRadius: '8px',
  width: '400px',
  maxWidth: '90%',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  zIndex: 1000,
};

const formStyles = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
};

const inputStyles = {
  display: 'block',
  width: '80%',
  padding: '10px',
  margin: '10px 0',
  borderRadius: '4px',
  border: '1px solid #ccc',
  fontSize: '16px',
};

const buttonStyles = {
  backgroundColor: colors.primary,
  color: colors.white,
  border: '1px solid black',
  padding: '10px 20px',
  cursor: 'pointer',
  borderRadius: '4px',
  marginTop: '10px',
};

const googleButtonWrapperStyles = {
  border: 'none',
  background: 'none',
  padding: 0,
  marginTop: '10px',
  cursor: 'pointer',
  height: '40px',
  outline: 'none',
};

const googleButtonStyles = {
  height: '100%',
  objectFit: 'contain',
};

const linkStyles = {
  color: colors.primary,
  cursor: 'pointer',
};

const closeButtonStyles = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  background: 'transparent',
  border: 'none',
  fontSize: '20px',
  cursor: 'pointer',
};

export default SignUpModal;
