import React from 'react';

function FrontTraitsToggle({ visibleFeatures, toggleFeature }) {
  const features = [
    { key: 'pupilDistance', label: 'Pupil Distance' },
    { key: 'leftEyeLength', label: 'Left Eye Length' },
    { key: 'rightEyeLength', label: 'Right Eye Length' },
    { key: 'faceLength', label: 'Face Length' },
    { key: 'faceWidth', label: 'Face Width' },
    { key: 'noseWidth', label: 'Nose Width' },
    { key: 'noseHeight', label: 'Nose Height' },
    { key: 'mouthWidth', label: 'Mouth Width' },
    { key: 'eyeSpacing', label: 'Eye Spacing' },
    { key: 'eyeOuterDistance', label: 'Eye Outer Distance' },
    { key: 'leftEyeHeight', label: 'Left Eye Height' },
    { key: 'rightEyeHeight', label: 'Right Eye Height' },
    { key: 'jawWidth', label: 'Jaw Width' },
    { key: 'chinHeight', label: 'Chin Height' },
    { key: 'philtrumLength', label: 'Philtrum Length' },
    { key: 'midfaceLength', label: 'Midface Length' },
    { key: 'upperLipHeight', label: 'Upper Lip Height' },
    { key: 'lowerLipHeight', label: 'Lower Lip Height' },
    { key: 'bitemporalWidth', label: 'Bitemporal Width' },
  ];

  return (
    <div>
      {features.map((feature) => (
        <div
          key={feature.key}
          onClick={() => toggleFeature(feature.key)}
          style={{
            cursor: 'pointer',
            padding: '10px',
            margin: '5px 0',
            borderRadius: '10px',
            backgroundColor: visibleFeatures[feature.key] ? 'lightblue' : 'white',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
          }}
        >
          {feature.label}
        </div>
      ))}
    </div>
  );
}

export default FrontTraitsToggle;
