import React from 'react';

function FrontRatiosToggle({ visibleFeatures, toggleFeature }) {
  const ratios = [
    { key: 'eyeSeparationRatio', label: 'Eye Separation Ratio' },
    { key: 'eyeAspectRatioLeft', label: 'Eye Aspect Ratio (Left)' },
    { key: 'eyeAspectRatioRight', label: 'Eye Aspect Ratio (Right)' },
    { key: 'jawWidthRatio', label: 'Jaw Width Ratio' },
    { key: 'chinPhiltrumRatio', label: 'Chin Philtrum Ratio' },
    { key: 'midfaceRatio', label: 'Midface Ratio' },
    { key: 'midfaceCompactness', label: 'Midface Compactness' },
    { key: 'mouthNoseHarmony', label: 'Mouth Nose Harmony' },
    { key: 'eyeSpacingRatio', label: 'Eye Spacing Ratio' },
    { key: 'noseHeightToWidthRatio', label: 'Nose Height To Width Ratio' },
    { key: 'lipRatio', label: 'Lip Ratio' },
    { key: 'bitemporalToFaceWidthRatio', label: 'Bitemporal to Face Width Ratio' },
    { key: 'chinAngle', label: 'Chin Angle' },
    { key: 'jawFrontalAngle', label: 'Jaw Frontal Angle' },
    { key: 'leftCantileTilt', label: 'Left Cantile Tilt' },
    { key: 'rightCantileTilt', label: 'Right Cantile Tilt' },
    { key: 'facialThirds', label: 'Facial Thirds' },
  ];

  return (
    <div>
      {ratios.map((ratio) => (
        <div
          key={ratio.key}
          onClick={() => toggleFeature(ratio.key)}
          style={{
            cursor: 'pointer',
            padding: '10px',
            margin: '5px 0',
            borderRadius: '10px',
            backgroundColor: visibleFeatures[ratio.key] ? 'lightblue' : 'white',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
          }}
        >
          {ratio.label}
        </div>
      ))}
    </div>
  );
}

export default FrontRatiosToggle;
