import React, { useState } from 'react';
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, sendEmailVerification, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../../firebaseConfig';
import colors from '../../styles/colors';
import { useLocation } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import googleSignIn from '../../../assets/images/google_sign_in.png';
import VerifyEmailModal from './VerifyEmailModal';

function LoginModal({ isOpen, onClose, onSignUpClick }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // State to manage loading indicator
  const [showResendVerification, setShowResendVerification] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const { state } = useLocation();
  const functions = getFunctions();

  if (!isOpen) return null;

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      if (!user.emailVerified) {
        setError('Email not verified. Please check your inbox for a verification email.');
        setShowResendVerification(true);
        setLoading(false); // Stop loading
        return;
      }

      if (state?.data) {
        const LoginUser = httpsCallable(functions, 'loginUser');
        await LoginUser({ scanData: state?.data });
      }

      onClose();
    } catch (error) {
      console.error('Error signing in:', error);
      setError('Failed to sign in. Please check your email and password.');
      setShowResendVerification(false);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      setLoading(true); // Start loading
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const googleSignUpUser = httpsCallable(functions, 'googleSignUpUser');
      await googleSignUpUser({
        email: user.email,
        uid: user.uid,
        inputReferralCode: '',
        scanData: state?.data,
      });
      onClose();
    } catch (error) {
      console.error('Error with Google sign up:', error);
      setError('Failed to sign up with Google.');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleResendVerification = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const actionCodeSettings = {
          url: 'https://goldenratioai.com',
          handleCodeInApp: true,
        };
  
        await sendEmailVerification(user, actionCodeSettings);
        setShowVerifyModal(true);
        setError('');
      }
    } catch (error) {
      console.error('Error sending verification email:', error);
      setError('Failed to send verification email. Please try again.');
    }
  };

  const handleForgotPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, forgotPasswordEmail);
      setError('Password reset email sent. Please check your inbox.');
      setShowForgotPassword(false);
    } catch (error) {
      console.error('Error sending password reset email:', error);
      setError('Failed to send password reset email. Please check the email address.');
    }
  };

  return (
    <>
      <div style={modalOverlayStyles}>
        <div style={modalStyles}>
          <button onClick={onClose} style={closeButtonStyles}>×</button>
          <h2>Login</h2>
          <div style={formStyles}>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {showResendVerification && (
              <p>
                <span style={linkStyles} onClick={handleResendVerification}>Resend Verification Email</span>
              </p>
            )}
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={inputStyles}
              disabled={loading}
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={inputStyles}
              disabled={loading}
            />
            <button onClick={handleLogin} style={buttonStyles} disabled={loading}>
              {loading ? 'Logging In...' : 'Login'}
            </button>
            <button onClick={handleGoogleSignIn} style={googleButtonWrapperStyles} disabled={loading}>
              <img
                src={googleSignIn}
                alt="Sign in with Google"
                style={googleButtonStyles}
              />
            </button>
            <p>
              <span style={linkStyles} onClick={() => setShowForgotPassword(true)}>Forgot Password?</span>
            </p>
            <p>
              Don't have an account? <span style={linkStyles} onClick={onSignUpClick}>Sign up here</span>
            </p>
          </div>
        </div>
      </div>
      {showForgotPassword && (
        <div style={modalOverlayStyles}>
          <div style={forgotPasswordModalStyles}>
            <button onClick={() => setShowForgotPassword(false)} style={closeButtonStyles}>×</button>
            <h2>Forgot Password</h2>
            <div style={formStyles}>
              <input
                type="email"
                placeholder="Enter your email"
                value={forgotPasswordEmail}
                onChange={(e) => setForgotPasswordEmail(e.target.value)}
                style={inputStyles}
              />
              <button onClick={handleForgotPassword} style={buttonStyles}>Send Reset Link</button>
            </div>
          </div>
        </div>
      )}
      <VerifyEmailModal isOpen={showVerifyModal} onClose={() => setShowVerifyModal(false)} />
    </>
  );
}

const modalOverlayStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 999,
};

const modalStyles = {
  position: 'relative',
  backgroundColor: colors.background,
  padding: '20px',
  borderRadius: '8px',
  width: '400px',
  maxWidth: '90%',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  zIndex: 1000,
};

const forgotPasswordModalStyles = {
  position: 'relative',
  backgroundColor: '#f0f0f0', // Different background color for distinction
  padding: '20px',
  borderRadius: '8px',
  border: '1px solid #ccc', // Add a border
  width: '400px',
  maxWidth: '90%',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  zIndex: 1000,
};

const formStyles = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
};

const inputStyles = {
  display: 'block',
  width: '80%',
  padding: '10px',
  margin: '10px 0',
  borderRadius: '4px',
  border: '1px solid #ccc',
  fontSize: '16px',
};

const buttonStyles = {
  backgroundColor: colors.primary,
  color: colors.white,
  border: '1px solid black',
  padding: '10px 20px',
  cursor: 'pointer',
  borderRadius: '4px',
  marginTop: '10px',
  height: '40px',
};

const googleButtonWrapperStyles = {
  border: 'none',
  background: 'none',
  padding: 0,
  marginTop: '10px',
  cursor: 'pointer',
  height: '40px',
  outline: 'none',
};

const googleButtonStyles = {
  height: '100%',
  objectFit: 'contain',
};

const linkStyles = {
  color: colors.primary,
  cursor: 'pointer',
};

const closeButtonStyles = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  background: 'transparent',
  border: 'none',
  fontSize: '20px',
  cursor: 'pointer',
};

export default LoginModal;
