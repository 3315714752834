import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { FaCopy } from 'react-icons/fa';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { auth, firestore } from '../../../firebaseConfig';
import colors from '../../styles/colors';
import { useUser } from './UserContext';
import { fetchStripeProducts, createStripeCheckoutSession } from './stripeService';

const PurchaseModal = ({ isOpen, onClose }) => {
  const [referralCode, setReferralCode] = useState('');
  const [referralCount, setReferralCount] = useState(0);
  const [showCopiedPopup, setShowCopiedPopup] = useState(false);
  const { setCredits } = useUser();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const productMapping = {
    "GR Credits (1)": "1 Credit",
    "GR Credits (10)": "10 Credits",
    "GR Credits (50)": "50 Credits",
  };

  const valueMultiplier = {
    "GR Credits (10)": "4x Value",
    "GR Credits (50)": "10x Value",
  };

  useEffect(() => {
    if (isOpen) {
      const fetchUserDetails = async () => {
        const user = auth.currentUser;
        if (user) {
          const userDoc = await getDoc(doc(firestore, 'users', user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setReferralCode(userData.referral_code);
            setReferralCount(userData.num_referrals);
          }
        }
      };

      const fetchProducts = async () => {
        const allProducts = await fetchStripeProducts();
        // Filter out products that contain "Testing" in their name
        const filteredProducts = allProducts.filter(product => !product.name.includes('Testing'));
        setProducts(filteredProducts);
      };

      fetchUserDetails();
      fetchProducts();
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const handleCopy = () => {
    navigator.clipboard.writeText(referralCode);
    setShowCopiedPopup(true);
    setTimeout(() => setShowCopiedPopup(false), 500);
  };

  const handlePurchase = async (priceId) => {
    setLoading(true);
    try {
      await createStripeCheckoutSession(priceId, true);
    } catch (error) {
      console.error('Error during purchase: ', error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 5000); // Ensures loading state is shown for at least 5 seconds
    }
  };

  return createPortal(
    <div style={overlayStyles}>
      <div style={modalStyles}>
        <button onClick={onClose} style={closeButtonStyles}>x</button>
        <h2 style={titleStyles}>Purchase More Credits</h2>
        <div style={contentStyles}>
          {loading ? (
            <div style={loadingStyles}>Processing...</div>
          ) : (
            products.map(product => (
              <div key={product.priceId} style={tierStyles} onClick={() => handlePurchase(product.priceId)}>
                <span>{productMapping[product.name] || product.name}</span>
                <span>${product.priceInfo.unit_amount / 100}</span>
                {valueMultiplier[product.name] && (
                  <div style={valueBubbleStyles}>{valueMultiplier[product.name]}</div>
                )}
              </div>
            ))
          )}
        </div>
        <p style={referralStyles}>or refer a friend with your referral code to get 1 free credit</p>
        <div style={referralSectionStyles}>
          <span style={referralCodeStyles}>{referralCode}</span>
          <button onClick={handleCopy} style={copyButtonStyles}>
            <FaCopy />
          </button>
        </div>
        <p style={referralCountStyles}>{referralCount} referred so far</p>
        {showCopiedPopup && <div style={copiedPopupStyles}>Copied!</div>}
      </div>
    </div>,
    document.body
  );
};

const overlayStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
};

const modalStyles = {
  backgroundColor: colors.white,
  padding: '20px',
  borderRadius: '8px',
  width: '400px',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  position: 'relative',
};

const closeButtonStyles = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  backgroundColor: 'transparent',
  border: 'none',
  fontSize: '18px',
  cursor: 'pointer',
};

const titleStyles = {
  margin: '0 0 20px',
  textAlign: 'center',
};

const contentStyles = {
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
  textAlign: 'center',
};

const tierStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '10px 20px',
  border: `1px solid ${colors.primary}`,
  borderRadius: '4px',
  cursor: 'pointer',
  position: 'relative',
};

const valueBubbleStyles = {
  position: 'absolute',
  top: '0px',
  right: '0px',
  backgroundColor: colors.primary,
  color: colors.white,
  padding: '5px 10px',
  borderRadius: '50%',
  fontSize: '12px',
  fontWeight: 'bold',
  transform: 'translate(50%, -50%)',
};

const referralStyles = {
  textAlign: 'center',
  marginTop: '20px',
};

const referralSectionStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  marginTop: '10px',
};

const referralCodeStyles = {
  padding: '5px 10px',
  border: `1px solid ${colors.primary}`,
  borderRadius: '4px',
  backgroundColor: colors.lightGrey,
  fontFamily: 'monospace',
  fontSize: '20px',
};

const copyButtonStyles = {
  backgroundColor: colors.primary,
  color: colors.white,
  border: 'none',
  borderRadius: '4px',
  padding: '5px 10px',
  cursor: 'pointer',
};

const referralCountStyles = {
  textAlign: 'center',
  marginTop: '10px',
};

const copiedPopupStyles = {
  position: 'absolute',
  bottom: '20px',
  left: '50%',
  transform: 'translateX(-50%)',
  backgroundColor: colors.primary,
  color: colors.white,
  padding: '10px 20px',
  borderRadius: '4px',
};

const loadingStyles = {
  textAlign: 'center',
  marginTop: '20px',
  color: colors.primary,
};

export default PurchaseModal;
