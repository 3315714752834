// src/UserContext.js
import React, { createContext, useState, useContext } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [credits, setCredits] = useState(0);

  return (
    <UserContext.Provider value={{ credits, setCredits }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
