import React, { useState, useMemo, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import FrontResultsComponent from '../components/FrontResultsComponent';
import SideResultsComponent from '../components/SideResultsComponent';
import FailedSideResultsComponent from '../../General/FailedSideResultsComponent';
import FailedFrontResultsComponent from '../../General/FailedFrontResultsComponent';
import ProgressBar from '../../General/ProgressBar';
import BellCurve from '../../General/BellCurve';
import colors from '../../styles/colors';
import { calculateScorePercentile } from '../components/utils';

function UnlockedResultsPage({ data, scans }) {
  const [currentView, setCurrentView] = useState('front');
  const navigate = useNavigate();
  const scrollRef = useRef(null);

  const handleFrontClick = () => {
    setCurrentView('front');
  };

  const handleSideClick = () => {
    setCurrentView('side');
    console.log(data.sideResult.score)
  };

  const calculateOverallScore = useCallback(() => {
    if (!data?.frontResult?.score && !data?.sideResult?.score) {
      return 0;
    }

    const frontScore = data?.frontResult?.score || data?.sideResult?.score;
    const sideScore = data?.sideResult?.score || frontScore;

    if (frontScore === 0 || sideScore === 0) {
      return 'Scan failed';
    }

    const higherScore = Math.max(frontScore, sideScore);
    const lowerScore = Math.min(frontScore, sideScore);

    return (higherScore * (2 / 3) + lowerScore * (1 / 3)).toFixed(2);
  }, [data?.frontResult?.score, data?.sideResult?.score]);

  const overallScore = useMemo(() => calculateOverallScore(), [calculateOverallScore]);
  const overallScoreValue = useMemo(() => (overallScore !== 'Scan failed' ? Math.ceil(overallScore * 10) : 0), [overallScore]);
  const overallScorePercentile = useMemo(() => (overallScoreValue !== 0 ? calculateScorePercentile(overallScoreValue / 10) : 0), [overallScoreValue]);

  const handleScanClick = (scan) => {
    navigate('/results', { state: { data: scan } });
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000);
    const today = new Date();
    if (date.toDateString() === today.toDateString()) {
      return date.toLocaleTimeString();
    } else {
      return date.toLocaleDateString();
    }
  };

  const scrollLeft = () => {
    scrollRef.current.scrollBy({ left: -200, behavior: 'smooth' });
  };

  const scrollRight = () => {
    scrollRef.current.scrollBy({ left: 200, behavior: 'smooth' });
  };

  return (
    <div style={{ background: colors.background, padding: 40, minWidth: '1100px', overflowX: 'auto'  }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ 
          marginRight: '20px',
          display: 'flex',
          justifyContent: 'flex-start',
          flex: '1 1 25%',
          height: '150px', 
          width: 'auto', 
          objectFit: 'contain',
        }}>
          <BellCurve score={overallScorePercentile}/>
        </div>
        <div style={{ textAlign: 'center', flex: '1 1 25%' }}>
          <h2>Overall Score: {overallScoreValue}</h2>
          <ProgressBar percent={overallScorePercentile} style={{ width: '100%' }} />
        </div>
        <div style={{ 
          display: 'flex',
          justifyContent: 'flex-end',
          flex: '1 1 25%',
          height: '150px', 
          width: 'auto', 
          objectFit: 'contain',
        }}>
          {currentView === 'front' && data?.sideResult && (
            <img
              src={data.sideResult.image_path}
              alt="Side Profile"
              onClick={handleSideClick}
              style={{ 
                cursor: 'pointer', 
                height: '150px', 
                width: 'auto', 
                border: `8px solid ${colors.primary}`, 
                borderRadius: '12px',
                overflow: 'hidden'
              }}
            />
          )}
          {currentView === 'side' && data?.frontResult && (
            <img
              src={data.frontResult.image_path}
              alt="Front Profile"
              onClick={handleFrontClick}
              style={{ 
                cursor: 'pointer', 
                height: '150px', 
                width: 'auto', 
                border: `8px solid ${colors.primary}`, 
                borderRadius: '12px',
                overflow: 'hidden'
              }}
            />
          )}
          {!data?.frontResult && !data?.sideResult && (
            <div>No front/side profile</div>
          )}
        </div>
      </div>
      <div>
        {currentView === 'front' && data?.frontResult && (
          data.frontResult.score === 0 ? (
            <FailedFrontResultsComponent imagePath={data.sideResult.image_path} />
          ) : (
            <FrontResultsComponent data={data.frontResult} imagePath={data.frontResult.image_path} />
          )
        )}
        {currentView === 'side' && data?.sideResult && (
          (data.sideResult.score === 0 || data.sideResult.score === null)? (
            <FailedSideResultsComponent imagePath={data.sideResult.image_path} />
          ) : (
            <SideResultsComponent data={data.sideResult} imagePath={data.sideResult.image_path} />
          )
        )}
      </div>
    </div>
  );
}

export default UnlockedResultsPage;
