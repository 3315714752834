import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import fullLogo from '../../../assets/images/logo.png';
import mobileLogo from '../../../assets/images/logo_mobile.png';
import colors from '../../styles/colors';
import LoginModal from './LoginModal';
import SignUpModal from './SignUpModal';

function UnauthorizedHeader() {
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [isSignUpModalOpen, setSignUpModalOpen] = useState(false);
  const [logoSrc, setLogoSrc] = useState(fullLogo);
  const [navGap, setNavGap] = useState(calculateNavGap(window.innerWidth));
  const [userGap, setUserGap] = useState(calculateUserGap(window.innerWidth));

  function calculateNavGap(width) {
    if (width < 600) return '10px';
    if (width < 1000) return '20px';
    return '30px';
  }

  function calculateUserGap(width) {
    if (width < 600) return '10px';
    if (width < 1000) return '20px';
    return '30px';
  }

  useEffect(() => {
    const updateLogoAndGaps = () => {
      setLogoSrc(window.innerWidth < 680 ? mobileLogo : fullLogo);
      setNavGap(calculateNavGap(window.innerWidth));
      setUserGap(calculateUserGap(window.innerWidth));
    };

    updateLogoAndGaps();
    window.addEventListener('resize', updateLogoAndGaps);

    return () => {
      window.removeEventListener('resize', updateLogoAndGaps);
    };
  }, []);

  const openLoginModal = () => {
    setLoginModalOpen(true);
    document.body.classList.add('modal-open');
  };

  const closeLoginModal = () => {
    setLoginModalOpen(false);
    document.body.classList.remove('modal-open');
  };

  const openSignUpModal = () => {
    setSignUpModalOpen(true);
    document.body.classList.add('modal-open');
  };

  const closeSignUpModal = () => {
    setSignUpModalOpen(false);
    document.body.classList.remove('modal-open');
  };

  useEffect(() => {
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, []);

  return (
    <>
      <header style={headerStyles}>
        <img src={logoSrc} alt="Logo" style={logoStyles} onClick={() => window.location.href = '/upload'} />
        <div style={{ ...rightContainerStyles, gap: userGap }}>
          <nav style={{ ...navStyles, gap: navGap }}>
            <Link to="/upload" style={linkStyles}>Upload</Link>
            <Link to="/contact" style={linkStyles}>Contact Us</Link>
          </nav>
          <button onClick={openLoginModal} style={buttonStyles}>Login</button>
        </div>
      </header>
      {isLoginModalOpen || isSignUpModalOpen ? <div style={backdropStyles}></div> : null}
      <LoginModal
        isOpen={isLoginModalOpen}
        onClose={closeLoginModal}
        onSignUpClick={() => {
          closeLoginModal();
          openSignUpModal();
        }}
      />
      <SignUpModal
        isOpen={isSignUpModalOpen}
        onClose={closeSignUpModal}
        onLoginClick={() => {
          closeSignUpModal();
          openLoginModal();
        }}
      />
    </>
  );
}

const headerStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px 20px',
  backgroundColor: colors.background,
  boxSizing: 'border-box',
  overflowX: 'auto',
  whiteSpace: 'nowrap',
};

const logoStyles = {
  height: '40px', // Fixed height to maintain consistent size
  cursor: 'pointer',
};

const rightContainerStyles = {
  display: 'flex',
  alignItems: 'center',
};

const navStyles = {
  display: 'flex',
  alignItems: 'center',
};

const linkStyles = {
  textDecoration: 'none',
  color: colors.text,
  fontWeight: 'bold',
  fontSize: '16px', // Slightly smaller font for better fit
};

const buttonStyles = {
  backgroundColor: colors.primary,
  color: colors.white,
  border: 'none',
  padding: '5px 10px',
  cursor: 'pointer',
  fontSize: '14px', // Slightly smaller font for better fit
};

const backdropStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 998, // Ensure it is below the modal
};

export default UnauthorizedHeader;
