import React, { useEffect, useRef, useState } from 'react';
import ProcessedImage from './ProcessedImage';
import colors from '../styles/colors';

function FailedSideResultsComponent({ imagePath }) {
  const canvasRef = useRef(null);
  const [imageHeight, setImageHeight] = useState(0);

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      const image = new Image();
      image.src = imagePath;

      image.onload = () => {
        const screenHalfWidth = window.innerWidth / 2;
        const scale = screenHalfWidth / image.width;

        const calculatedHeight = image.height * scale;
        setImageHeight(calculatedHeight);

        canvas.width = screenHalfWidth;
        canvas.height = calculatedHeight;

        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      };
    }
  }, [imagePath]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 40 }}>
      <h2 style={{ color: colors.primary, marginBottom: '20px' }}>Scan Failed</h2>
      <p style={{ marginBottom: '20px', textAlign: 'center' }}>
        Unfortunately, this scan did not produce any measurable results. Please try again or check the scan for any issues.
      </p>
      <div style={{ marginBottom: '20px' }}>
        {imagePath && (
          <div style={{ padding: 30 }}>
            <ProcessedImage canvasRef={canvasRef} style={{ 
              border: `12px solid ${colors.primary}`, 
              borderRadius: '25px',
              maxHeight: '400px'
            }} />
          </div>
        )}
      </div>
    </div>
  );
}

export default FailedSideResultsComponent;
