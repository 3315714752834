import React, { useState, useMemo } from 'react';
import ProcessedImage from '../../General/ProcessedImage';
import SideFeaturesToggle from '../../Authorized/components/SideFeaturesToggle';
import ProgressBar from '../../General/ProgressBar';
import colors from '../../styles/colors';
import BellCurve from '../../General/BellCurve';
import { calculateScorePercentile } from '../../Authorized/components/utils';
import UnlockingModal from './UnlockingModal';
import LoginModal from '../../Unauthorized/components/LoginModal';

function LockedSideResultsComponent({ data, imagePath, onUnlockConfirm }) {
  const [visibleFeatures, setVisibleFeatures] = useState({
    philtrumFlatness: false,
    leftGonialAngle: false,
    nasofrontalAngle: false,
    nasalAngle: false,
    nasolabialAngle: false,
    nasalProjection: false,
    totalFacialConvexity: false,
    facialConvexity: false,
    mandibularPlaneAngle: false,
  });

  const [isUnlockingModalOpen, setIsUnlockingModalOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const toggleFeature = (feature) => {
    setVisibleFeatures((prev) => ({
      ...prev,
      [feature]: !prev[feature],
    }));
  };

  const bestFeatures = useMemo(() => [
    { name: 'Best Feature 1', value: 100, percent: 80 },
    { name: 'Best Feature 2', value: 90, percent: 70 },
    { name: 'Best Feature 3', value: 80, percent: 60 },
  ], []);

  const worstFeatures = useMemo(() => [
    { name: 'Worst Feature 1', value: 30, percent: 20 },
    { name: 'Worst Feature 2', value: 20, percent: 15 },
    { name: 'Worst Feature 3', value: 10, percent: 10 },
  ], []);

  const userScore = useMemo(() => Math.ceil(data.score * 10), [data.score]);
  const userScorePercentile = useMemo(() => calculateScorePercentile(userScore / 10), [userScore]);

  const handleBlurClick = () => {
    setIsUnlockingModalOpen(true);
    document.body.classList.add('modal-open');
  };

  const closeUnlockingModal = () => {
    setIsUnlockingModalOpen(false);
    document.body.classList.remove('modal-open');
  };

  const closeLoginModal = () => {
    setIsLoginModalOpen(false);
    document.body.classList.remove('modal-open');
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
        {imagePath && (
          <div style={{ flex: '1 1 30%', marginTop: '30px', display: 'flex', flexDirection: 'column' }}>
            <div style={{ position: 'relative', padding: '10px', border: '2px solid gray', borderRadius: '10px', marginBottom: '10px', filter: 'blur(3px)', cursor: 'pointer' }} onClick={handleBlurClick}>
              <h2 style={{ textAlign: 'center' }}>Section Scores Coming Soon</h2>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <span role="img" aria-label="eye">👁️</span>
                <ProgressBar percent={50} style={{ width: '100%' }} />
                <span role="img" aria-label="mouth">👄</span>
                <ProgressBar percent={50} style={{ width: '100%' }} />
                <span role="img" aria-label="nose">👃</span>
                <ProgressBar percent={50} style={{ width: '100%' }} />
              </div>
            </div>
            <button style={{ marginBottom: '10px', padding: '10px', backgroundColor: colors.primary, color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer', width: '100%', filter: 'blur(3px)' }} onClick={handleBlurClick}>
              Show Best and Worst Features
            </button>
            <div style={{ minHeight: '400px', overflowY: 'auto', border: '2px solid gray', borderRadius: '10px', padding: '10px', flexGrow: 1, filter: 'blur(3px)', cursor: 'pointer' }} onClick={handleBlurClick}>
              <>
                <div>
                  <h3>Best Features</h3>
                  {bestFeatures.map((feature, index) => (
                    <div key={index} style={{ padding: '5px', margin: '5px 0' }}>
                      <strong>{feature.name}:</strong> {feature.value}
                      <ProgressBar percent={feature.percent} style={{ width: '100%' }} />
                    </div>
                  ))}
                </div>
                <div>
                  <h3>Worst Features</h3>
                  {worstFeatures.map((feature, index) => (
                    <div key={index} style={{ padding: '5px', margin: '5px 0' }}>
                      <strong>{feature.name}:</strong> {feature.value}
                      <ProgressBar percent={feature.percent} style={{ width: '100%' }} />
                    </div>
                  ))}
                </div>
              </>
            </div>
          </div>
        )}
        {imagePath && (
          <div style={{ flex: '1 1 40%', padding: 30 }}>
            <img src={imagePath} alt="Processed" style={{ 
                width: '100%', 
                border: `12px solid ${colors.primary}`, 
                borderRadius: '25px'
              }} />
          </div>
        )}
        <div style={{ flex: '1 1 30%', marginLeft: '20px', marginTop: '30px' }}>
          {data && (
            <>
              <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                <strong style={{ fontSize: '24px', color: 'black' }}>Side Profile Score: {userScore}</strong> 
                <ProgressBar percent={userScorePercentile} style={{ width: '100%' }} />
                <BellCurve score={userScorePercentile} style={{ width: '100%' }} />
              </div>
              <button style={{ marginBottom: '10px', padding: '10px', backgroundColor: colors.primary, color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer', width: '100%', filter: 'blur(3px)' }} onClick={handleBlurClick}>
                Show Traits
              </button>
              <div style={{ minHeight: '400px', overflowY: 'auto', padding: '10px', flexGrow: 1, border: '2px solid gray', borderRadius: '10px', filter: 'blur(3px)', cursor: 'pointer' }} onClick={handleBlurClick}>
                <SideFeaturesToggle visibleFeatures={visibleFeatures} toggleFeature={toggleFeature} />
              </div>
            </>
          )}
        </div>
      </div>
      {isLoginModalOpen || isUnlockingModalOpen ? <div style={backdropStyles}></div> : null}
      <UnlockingModal 
        isOpen={isUnlockingModalOpen} 
        onClose={closeUnlockingModal} 
        onConfirm={() => {
          closeUnlockingModal();
          onUnlockConfirm();
        }} 
      />
      <LoginModal 
        isOpen={isLoginModalOpen} 
        onClose={closeLoginModal} 
        onSignUpClick={() => {
          closeLoginModal();
          setIsUnlockingModalOpen(true);
          document.body.classList.add('modal-open');
        }} 
      />
    </div>
  );
}

const backdropStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 998, 
};

export default LockedSideResultsComponent;
