import React from 'react';
import colors from '../styles/colors';

function Popup({ message, onClose }) {
  const popupStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: colors.background,
    border: `2px solid ${colors.primary}`, // Added border to stand out
    padding: '20px',
    borderRadius: '10px',
    width: '300px',
    textAlign: 'center',
    zIndex: 1000,
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Added box shadow for better visibility
  };

  const buttonStyle = {
    marginTop: '10px',
    backgroundColor: colors.primary,
    color: colors.white,
    border: 'none',
    padding: '10px',
    cursor: 'pointer',
  };

  return (
    <div style={popupStyle}>
      <p>{message}</p>
      <button onClick={onClose} style={buttonStyle}>
        Close
      </button>
    </div>
  );
}

export default Popup;