// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebaseConfig'; // Import Firebase auth
import AuthListener from './components/General/AuthListener';
import AuthRoute from './navigation/AuthRoute';
import UnauthRoute from './navigation/UnauthRoute';
import UploadPage from './components/Authorized/screens/UploadPage';
import ScansPage from './components/Authorized/screens/ScansPage';
import ResultsPage from './components/Authorized/screens/ResultsPage';
import AuthorizedHeader from './components/Authorized/components/AuthorizedHeader';
import UnauthorizedHeader from './components/Unauthorized/components/UnauthorizedHeader';
import UnauthorizedUploadPage from './components/Unauthorized/screens/UnauthorizedUploadPage';
import UnauthorizedResultsPage from './components/Unauthorized/screens/UnauthorizedResultsPage';
import AuthorizedContactUs from './components/Authorized/screens/AuthorizedContactUs';
import UnauthorizedContactUs from './components/Unauthorized/screens/UnauthorizedContactUs';
import { UserProvider } from './components/Authorized/components/UserContext';
import colors from './components/styles/colors';

function App() {
  const [user, setUser] = useState(null);
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setIsVerified(currentUser?.emailVerified || false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <UserProvider value={user}>
      <div style={{ backgroundColor: colors.background, minHeight: '100vh' }}>
        <Router>
          <AuthListener setUser={setUser} />
          {user && isVerified ? <AuthorizedHeader user={user} /> : <UnauthorizedHeader />}
          <Routes>
            <Route
              path="/upload"
              element={user && isVerified ? <AuthRoute component={UploadPage} user={user} /> : <UnauthRoute component={UnauthorizedUploadPage} />}
            />
            <Route
              path="/results"
              element={user && isVerified ? <AuthRoute component={ResultsPage} user={user} /> : <UnauthRoute component={UnauthorizedResultsPage} />}
            />
            <Route
              path="/scans"
              element={user && isVerified ? <AuthRoute component={ScansPage} user={user} /> : <Navigate to="/unauthorized" />}
            />
            <Route
              path="/contact"
              element={user && isVerified ? <AuthRoute component={AuthorizedContactUs} user={user} /> : <UnauthRoute component={UnauthorizedContactUs} />}
            />
            <Route path="*" element={<Navigate to={user && isVerified ? "/upload" : "/upload"} />} />
          </Routes>
        </Router>
      </div>
    </UserProvider>
  );
}

export default App;
