import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebaseConfig';

const UnauthRoute = ({ component: Component, ...rest }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [isVerified, setIsVerified] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
      setIsVerified(user?.emailVerified || false);
    });

    return () => unsubscribe();
  }, []);

  if (isAuthenticated === null || isVerified === null) {
    return <div>Loading...</div>; // or a loading spinner
  }

  if (isAuthenticated && isVerified) {
    return <Navigate to="/" />;
  }

  return <Component {...rest} />;
};

export default UnauthRoute;
