import React, { useEffect, useRef, useState, useMemo } from 'react';
import ProcessedImage from '../../General/ProcessedImage';
import SideFeaturesToggle from './SideFeaturesToggle';
import ProgressBar from '../../General/ProgressBar';
import colors from '../../styles/colors';
import BellCurve from '../../General/BellCurve';
import { calculateScorePercentile } from './utils';

function SideResultsComponent({ data, imagePath }) {
  const canvasRef = useRef(null);
  const [visibleFeatures, setVisibleFeatures] = useState({
    philtrumFlatness: false,
    leftGonialAngle: false,
    nasofrontalAngle: false,
    nasalAngle: false,
    nasolabialAngle: false,
    nasalProjection: false,
    totalFacialConvexity: false,
    facialConvexity: false,
    mandibularPlaneAngle: false,
  });

  const [showAllFeatures, setShowAllFeatures] = useState(false);
  const [sortOrder, setSortOrder] = useState('default');
  const [imageHeight, setImageHeight] = useState(0);

  const toggleFeature = (feature) => {
    setVisibleFeatures((prev) => ({
      ...prev,
      [feature]: !prev[feature],
    }));
  };

  useEffect(() => {
    if (data && canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      const image = new Image();
      image.src = imagePath;

      image.onload = () => {
        const screenHalfWidth = window.innerWidth / 2;
        const scale = screenHalfWidth / image.width;

        const calculatedHeight = image.height * scale;
        setImageHeight(calculatedHeight);

        canvas.width = screenHalfWidth;
        canvas.height = calculatedHeight;

        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

        ctx.save();
        ctx.scale(scale, scale);

        const drawLine = (point1, point2) => {
          ctx.beginPath();
          ctx.moveTo(point1[0], point1[1]);
          ctx.lineTo(point2[0], point2[1]);
          ctx.stroke();
        };

        ctx.strokeStyle = 'blue';
        ctx.lineWidth = 3 / scale;

        if (visibleFeatures.philtrumFlatness) {
          drawLine(data.measurements_coords["Chin"], data.measurements_coords["Nose Interior"]);
          drawLine(data.measurements_coords["Nose Interior"], data.measurements_coords["Upper Lip"]);
        }
        if (visibleFeatures.leftGonialAngle) {
          drawLine(data.measurements_coords["Left Jaw Angle"], data.measurements_coords["Left Ramus"]);
          drawLine(data.measurements_coords["Left Jaw Angle"], data.measurements_coords["Chin"]);
        }
        if (visibleFeatures.nasofrontalAngle) {
          drawLine(data.measurements_coords["Forehead"], data.measurements_coords["Nose Bridge"]);
          drawLine(data.measurements_coords["Nose Bridge"], data.measurements_coords["Nose Tip"]);
        }
        if (visibleFeatures.nasalAngle) {
          drawLine(data.measurements_coords["Nose Base Left"], data.measurements_coords["Nose Tip"]);
          drawLine(data.measurements_coords["Nose Tip"], data.measurements_coords["Nose Base Right"]);
        }
        if (visibleFeatures.nasolabialAngle) {
          drawLine(data.measurements_coords["Subnasale"], data.measurements_coords["Nose Interior"]);
          drawLine(data.measurements_coords["Nose Interior"], data.measurements_coords["Upper Lip"]);
        }
        if (visibleFeatures.nasalProjection) {
          drawLine(data.measurements_coords["Nose Tip"], data.measurements_coords["Nose Bridge"]);
          drawLine(data.measurements_coords["Nose Tip"], data.measurements_coords["Nasal Projection Point"]);
        }
        if (visibleFeatures.totalFacialConvexity) {
          drawLine(data.measurements_coords["Glabella"], data.measurements_coords["Subnasale"]);
          drawLine(data.measurements_coords["Subnasale"], data.measurements_coords["Pogonion"]);
        }
        if (visibleFeatures.facialConvexity) {
          drawLine(data.measurements_coords["Glabella"], data.measurements_coords["Nose Interior"]);
          drawLine(data.measurements_coords["Nose Interior"], data.measurements_coords["Pogonion"]);
        }
        if (visibleFeatures.mandibularPlaneAngle) {
          drawLine(data.measurements_coords["Nasion"], data.measurements_coords["Gonion Left"]);
          drawLine(data.measurements_coords["Menton"], data.measurements_coords["Gonion Right"]);
        }
      };
    }
  }, [data, imagePath, visibleFeatures]);

  const getTopPercentiles = useMemo(() => {
    const sortedKeys = Object.keys(data.percentiles).sort((a, b) => data.percentiles[b] - data.percentiles[a]);
    return sortedKeys.slice(0, 3);
  }, [data]);

  const getBottomPercentiles = useMemo(() => {
    const sortedKeys = Object.keys(data.percentiles).sort((a, b) => data.percentiles[a] - data.percentiles[b]);
    return sortedKeys.slice(0, 3);
  }, [data]);

  const handleToggleView = () => {
    setShowAllFeatures((prev) => !prev);
  };

  const handleSortChange = (e) => {
    setSortOrder(e.target.value);
  };

  const sortedFeatures = useMemo(() => {
    const features = Object.keys(data.percentiles);
    if (sortOrder === 'best-to-worst') {
      return features.sort((a, b) => data.percentiles[b] - data.percentiles[a]);
    } else if (sortOrder === 'worst-to-best') {
      return features.sort((a, b) => data.percentiles[a] - data.percentiles[b]);
    }
    return features;
  }, [data.percentiles, sortOrder]);

  const userScore = useMemo(() => Math.ceil(data.score * 10), [data.score]);
  const userScorePercentile = useMemo(() => calculateScorePercentile(userScore / 10), [userScore]);

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
        {imagePath && (
          <div style={{ flex: '1 1 30%', marginTop: '30px', display: 'flex', flexDirection: 'column' }}>
            <div style={{ position: 'relative', padding: '10px', border: '2px solid gray', borderRadius: '10px', marginBottom: '10px' }}>
              <h2 style={{ textAlign: 'center' }}>Section Scores Coming Soon</h2>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', filter: 'blur(3px)' }}>
                <span role="img" aria-label="eye">👁️</span>
                <ProgressBar percent={50} style={{ width: '100%' }} />
                <span role="img" aria-label="mouth">👄</span>
                <ProgressBar percent={50} style={{ width: '100%' }} />
                <span role="img" aria-label="nose">👃</span>
                <ProgressBar percent={50} style={{ width: '100%' }} />
              </div>
            </div>
            <button onClick={handleToggleView} style={{ marginBottom: '10px', padding: '10px', backgroundColor: colors.primary, color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer', width: '100%' }}>
              {showAllFeatures ? 'Show Best and Worst Features' : 'Show All Features'}
            </button>
            {showAllFeatures && (
              <div style={{ marginBottom: '10px' }}>
                <label htmlFor="sortOrder">Sort By: </label>
                <select id="sortOrder" value={sortOrder} onChange={handleSortChange}>
                  <option value="default">Default</option>
                  <option value="best-to-worst">Best to Worst</option>
                  <option value="worst-to-best">Worst to Best</option>
                </select>
              </div>
            )}
            <div style={{ maxHeight: `${imageHeight - 850 < 400 ? 400 : imageHeight - 850}px`, minHeight: '400', overflowY: 'auto', border: '2px solid gray', borderRadius: '10px', padding: '10px', flexGrow: 1 }}>
              {showAllFeatures ? (
                <div>
                  <h3>All Features</h3>
                  {sortedFeatures.map((key) => (
                    <div key={key} style={{ padding: '5px', margin: '5px 0' }}>
                      <strong>{key}:</strong> {data.data[key].toFixed(2)}
                      <ProgressBar percent={data.percentiles[key]} style={{ width: '100%' }} />
                    </div>
                  ))}
                </div>
              ) : (
                <>
                  <div>
                    <h3>Best Features</h3>
                    {getTopPercentiles.map((key) => (
                      <div key={key} style={{ padding: '5px', margin: '5px 0' }}>
                        <strong>{key}:</strong> {data.data[key].toFixed(2)}
                        <ProgressBar percent={data.percentiles[key]} style={{ width: '100%' }} />
                      </div>
                    ))}
                  </div>
                  <div>
                    <h3>Worst Features</h3>
                    {getBottomPercentiles.map((key) => (
                      <div key={key} style={{ padding: '5px', margin: '5px 0' }}>
                        <strong>{key}:</strong> {data.data[key].toFixed(2)}
                        <ProgressBar percent={data.percentiles[key]} style={{ width: '100%' }} />
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {imagePath && (
          <div style={{ flex: '1 1 40%', padding: 30 }}>
            <ProcessedImage canvasRef={canvasRef} style={{ 
                border: `12px solid ${colors.primary}`, 
                borderRadius: '25px'
              }} />
          </div>
        )}
        <div style={{ flex: '1 1 30%', marginLeft: '20px', marginTop: '30px', }}>
          {data && (
            <>
              <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                <strong style={{ fontSize: '24px', color: 'black' }}>Side Profile Score: {userScore}</strong> 
                <ProgressBar percent={userScorePercentile} style={{ width: '100%' }} />
                <BellCurve score={userScorePercentile} style={{ width: '100%' }} />
              </div>
              <div style={{ maxHeight: `${imageHeight - 850 < 400 ? 400 : imageHeight - 850}px`, overflowY: 'auto', padding: '10px', flexGrow: 1, border: '2px solid gray', borderRadius: '10px' }}>
                <SideFeaturesToggle visibleFeatures={visibleFeatures} toggleFeature={toggleFeature} />
              </div>
            </>
          )}
        </div>
      </div>
      {/* {data && (
        <>
          <h3>Side Profile Data</h3>
          {Object.keys(data.data).map((key) => (
            <div key={key} style={{ padding: '5px', margin: '5px 0' }}>
              <strong>{key}:</strong> {data.data[key]}
              <ProgressBar percent={data.percentiles[key]} style={{ width: '100%' }} />
            </div>
          ))}
        </>
      )} */}
    </div>
  );
}

export default SideResultsComponent;
