// Implementation of the error function
function erf(x) {
    // constants
    const a1 =  0.254829592;
    const a2 = -0.284496736;
    const a3 =  1.421413741;
    const a4 = -1.453152027;
    const a5 =  1.061405429;
    const p  =  0.3275911;
  
    // Save the sign of x
    const sign = Math.sign(x);
    x = Math.abs(x);
  
    // A&S formula 7.1.26
    const t = 1.0 / (1.0 + p * x);
    const y = 1.0 - (((((a5 * t + a4) * t) + a3) * t + a2) * t + a1) * t * Math.exp(-x * x);
  
    return sign * y;
  }
  
  export const calculateScorePercentile = (score, mean = 5, sd = 2.25) => {
    const z = (score - mean) / sd;
    const percentile = 0.5 * (1 + erf(z / Math.sqrt(2)));
    return percentile * 100;
  };
  