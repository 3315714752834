import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import UnauthorizedFrontResultsComponent from '../components/UnauthorizedFrontResultsComponent';
import UnauthorizedSideResultsComponent from '../components/UnauthorizedSideResultsComponent';
import FailedFrontResultsComponent from '../../General/FailedFrontResultsComponent';
import FailedSideResultsComponent from '../../General/FailedSideResultsComponent';
import ProgressBar from '../../General/ProgressBar';
import BellCurve from '../../General/BellCurve';
import CircularProgress from '@mui/material/CircularProgress';
import colors from '../../styles/colors';
import { calculateScorePercentile } from '../../Authorized/components/utils';
import '../../styles/ResultsPage.css';

function UnauthorizedResultsPage() {
  const { state } = useLocation();
  const { data, frontImage, sideImage } = state || {};
  const [currentView, setCurrentView] = useState('front');
  const [loading, setLoading] = useState(true);

  const handleFrontClick = () => {
    setCurrentView('front');
  };

  const handleSideClick = () => {
    setCurrentView('side');
  };

  useEffect(() => {
    if (data) {
      setLoading(false);
    }
  }, [data]);

  const calculateOverallScore = useCallback(() => {
    if (!data?.frontResult?.score && !data?.sideResult?.score) {
      return 0;
    }

    const frontScore = data?.frontResult?.score || data?.sideResult?.score;
    const sideScore = data?.sideResult?.score || frontScore;

    const higherScore = Math.max(frontScore, sideScore);
    const lowerScore = Math.min(frontScore, sideScore);

    return (higherScore * (2 / 3) + lowerScore * (1 / 3)).toFixed(2);
  }, [data?.frontResult?.score, data?.sideResult?.score]);

  const overallScore = useMemo(() => calculateOverallScore(), [calculateOverallScore]);
  const overallScoreValue = useMemo(() => Math.ceil(overallScore * 10), [overallScore]);
  const overallScorePercentile = useMemo(() => calculateScorePercentile(overallScoreValue / 10), [overallScoreValue]);

  return (
    <div style={{ background: colors.background, padding: 40, minWidth: '1100px', overflowX: 'auto' }}>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress sx={{ color: colors.primary }} size={150} />
        </div>
      ) : (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ 
              marginRight: '20px',
              display: 'flex',
              justifyContent: 'flex-start',
              flex: '1 1 25%',
              height: '150px', 
              width: 'auto', 
              objectFit: 'contain',
            }}>
              <BellCurve score={overallScorePercentile}/>
            </div>
            <div style={{ textAlign: 'center', flex: '1 1 25%' }}>
              <h2>Overall Score: {overallScoreValue}</h2>
              <ProgressBar percent={overallScorePercentile} style={{ width: '100%' }} />
            </div>
            <div style={{ 
              display: 'flex',
              justifyContent: 'flex-end',
              flex: '1 1 25%',
              height: '150px', 
              width: 'auto', 
              objectFit: 'contain',
            }}>
              {currentView === 'front' && data?.sideResult && (
                <img
                  src={`data:image/jpeg;base64,${sideImage}`}
                  alt="Side Profile"
                  onClick={handleSideClick}
                  style={{ 
                    cursor: 'pointer', 
                    height: '150px', 
                    width: 'auto', 
                    border: `8px solid ${colors.primary}`, 
                    borderRadius: '12px',
                    overflow: 'hidden'
                  }}
                />
              )}
              {currentView === 'side' && data?.frontResult && (
                <img
                  src={`data:image/jpeg;base64,${frontImage}`}
                  alt="Front Profile"
                  onClick={handleFrontClick}
                  style={{ 
                    cursor: 'pointer', 
                    height: '150px', 
                    width: 'auto', 
                    border: `8px solid ${colors.primary}`, 
                    borderRadius: '12px',
                    overflow: 'hidden'
                  }}
                />
              )}
              {!data?.frontResult && !data?.sideResult && (
                <div>No front/side profile</div>
              )}
            </div>
          </div>
          <div>
            {currentView === 'front' && data?.frontResult && (
                data.frontResult.score === 0 ? (
                  <FailedFrontResultsComponent imagePath={`data:image/jpeg;base64,${frontImage}`} />
                ) : (
                  <UnauthorizedFrontResultsComponent data={data.frontResult} imagePath={`data:image/jpeg;base64,${frontImage}`} />
                )
              )}
              {currentView === 'side' && data?.sideResult && (
                (data.sideResult.score === 0 || data.sideResult.score === null) ? (
                  <FailedSideResultsComponent imagePath={`data:image/jpeg;base64,${sideImage}`} />
                ) : (
                  <UnauthorizedSideResultsComponent data={data.sideResult} imagePath={`data:image/jpeg;base64,${sideImage}`} />
                )
            )}
          </div>
          {/* <div style={{ marginTop: '20px' }}>
            <h2>Data</h2>
            <pre>{JSON.stringify(data, null, 2)}</pre>
          </div> */}
        </>
      )}
    </div>
  );
}

export default UnauthorizedResultsPage;
