import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import colors from '../../styles/colors';
import Popup from '../../General/Popup';

function UnauthorizedContactUs() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const form = {
      user_name: 'Anonymous',
      user_email: email,
      message: message,
    };

    try {
      await emailjs.send('service_kielm2g', 'template_rtry15o', form, 'bz88vqD3wMQzO8MAN');
      setPopupMessage('Message sent successfully');
      setEmail('');
      setMessage('');
    } catch (error) {
      setPopupMessage('Error sending message');
    }

    setIsSubmitting(false);
  };

  const containerStyle = {
    textAlign: 'center',
    backgroundColor: colors.background,
    padding: '20px 40px',
    borderRadius: '10px',
    width: '450px',
    margin: '40px auto',
    overflowX: 'auto'
  };

  const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const inputStyle = {
    marginBottom: '10px',
    padding: '15px',
    fontSize: '18px',
    width: '100%',
  };

  const textareaStyle = {
    height: '200px',
    marginBottom: '20px',
    padding: '15px',
    fontSize: '18px',
    width: '100%',
  };

  const buttonStyle = {
    backgroundColor: colors.primary,
    color: colors.white,
    border: 'none',
    padding: '10px',
    cursor: 'pointer',
    alignSelf: 'center', // Center the button
  };

  return (
    <div style={containerStyle}>
      {popupMessage && <Popup message={popupMessage} onClose={() => setPopupMessage('')} />}
      <h1>Contact Us</h1>
      <p>Let us know any issues or suggestions for new features</p>
      <form onSubmit={handleSubmit} style={formStyle}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Your email"
          required
          style={inputStyle}
        />
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Your message"
          required
          style={textareaStyle}
          maxLength="2000"
        />
        <button type="submit" disabled={isSubmitting} style={buttonStyle}>
          {isSubmitting ? 'Sending...' : 'Send Message'}
        </button>
      </form>
    </div>
  );
}

export default UnauthorizedContactUs;