// In styles/colors.js or constants/colors.js
const colors = {
    background: '#F8FAFD',
    primary: '#67CAC7',
    white: '#FFFFFF',
    text: '#2c3e50',
    gray: '#769192',
    // add as many colors as you need
  };
  
  export default colors;
  