// src/components/General/VerifyEmailModal.js
import React from 'react';

function VerifyEmailModal({ isOpen, onClose }) {
  if (!isOpen) return null;

  const handleConfirm = () => {
    window.location.reload();
  };

  return (
    <div style={modalOverlayStyles}>
      <div style={modalStyles}>
        <h2>Please Verify Your Email</h2>
        <p>We have sent a verification email to your inbox. Please verify your email address to proceed.</p>
        <button onClick={handleConfirm} style={buttonStyles}>OK</button>
      </div>
    </div>
  );
}

const modalOverlayStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 999,
};

const modalStyles = {
  backgroundColor: '#fff',
  padding: '20px',
  borderRadius: '8px',
  textAlign: 'center',
  width: '300px',
};

const buttonStyles = {
  marginTop: '20px',
  padding: '10px 20px',
  backgroundColor: '#007bff',
  color: '#fff',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
};

export default VerifyEmailModal;
