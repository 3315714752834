import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/UploadPage.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import frontProfilePlaceholder from '../../../assets/images/front_profile_example1.png';
import sideProfilePlaceholder from '../../../assets/images/side_profile_example1.png';

function UnauthorizedUploadPage() {
  const [frontImage, setFrontImage] = useState(null);
  const [sideImage, setSideImage] = useState(null);
  const [frontDisplayImage, setFrontDisplayImage] = useState(frontProfilePlaceholder);
  const [sideDisplayImage, setSideDisplayImage] = useState(sideProfilePlaceholder);
  const [gender, setGender] = useState('male');
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();

  const handleFrontImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setFrontImage(e.target.files[0]);
      setFrontDisplayImage(URL.createObjectURL(e.target.files[0]));
    } else {
      setFrontImage(null);
      setFrontDisplayImage(frontProfilePlaceholder);
    }
  };

  const handleSideImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setSideImage(e.target.files[0]);
      setSideDisplayImage(URL.createObjectURL(e.target.files[0]));
    } else {
      setSideImage(null);
      setSideDisplayImage(sideProfilePlaceholder);
    }
  };

  const handleGenderChange = (e) => {
    setGender(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    setProgress(0);
    const frontImageBase64 = frontImage ? await toBase64(frontImage) : null;
    const sideImageBase64 = sideImage ? await toBase64(sideImage) : null;

    const incrementProgress = () => {
      let percent = 0;
      const interval = setInterval(() => {
        percent += 1;
        setProgress(percent);
        if (percent >= 100) {
          clearInterval(interval);
        }
      }, 150);
    };

    incrementProgress();

    try {
      const response = await fetch('https://us-central1-golden-ratio-23459.cloudfunctions.net/api/processUnauthorizedScan', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          frontImage: frontImageBase64,
          sideImage: sideImageBase64,
          gender,
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      const { scanData } = data;
      console.log(scanData);

      setTimeout(() => {
        setLoading(false);
        navigate('/results', { state: { data: scanData, frontImage: frontImageBase64, sideImage: sideImageBase64 } });
      }, 15000); // Ensure a minimum of 15 seconds loading time
    } catch (error) {
      console.error('Error processing scan:', error);
      setLoading(false);
    }
  };

  const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  return (
    <div className="upload-page" style={{ overflowX: 'auto' }}>
      <h1 className="upload-title">
        {loading ? 'Analyzing Your Face' : 'Upload Photos To See Your Facial Attractiveness'}
      </h1>
      {loading ? (
        <div className="loading-spinner">
          <CircularProgressbar
            value={progress}
            text={`${progress}%`}
            styles={buildStyles({
              pathColor: '#67CAC7',
              textColor: '#67CAC7',
              trailColor: '#d6d6d6',
              backgroundColor: '#3e98c7',
            })}
          />
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="upload-form">
          <div className="upload-sections">
            <div className="upload-section">
              <h2 className="section-title">Front Profile</h2>
              <div className="upload-box" onClick={() => document.getElementById('front-input').click()}>
                <img src={frontDisplayImage} alt="Front Profile" className="uploaded-image" />
                {!frontImage && <div className="placeholder-text">Upload Front Profile</div>}
                <input
                  id="front-input"
                  type="file"
                  accept="image/*"
                  onChange={handleFrontImageChange}
                  className="file-input"
                  style={{ display: 'none' }}
                />
              </div>
            </div>
            <div className="upload-section">
              <h2 className="section-title">Side Profile</h2>
              <div className="upload-box" onClick={() => document.getElementById('side-input').click()}>
                <img src={sideDisplayImage} alt="Side Profile" className="uploaded-image" />
                {!sideImage && <div className="placeholder-text">Upload Side Profile</div>}
                <input
                  id="side-input"
                  type="file"
                  accept="image/*"
                  onChange={handleSideImageChange}
                  className="file-input"
                  style={{ display: 'none' }}
                />
              </div>
            </div>
          </div>
          <div className="gender-section">
            <h2 className="section-title">Gender</h2>
            <select value={gender} onChange={handleGenderChange} className="gender-select" style={{ minWidth: '100px'}}>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
          </div>
          <button type="submit" className="upload-button">Upload</button>
        </form>
      )}
    </div>
  );
}

export default UnauthorizedUploadPage;
