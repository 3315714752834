import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, getDocs, orderBy } from 'firebase/firestore';
import { auth, firestore, storage } from '../../../firebaseConfig';
import { ref, getDownloadURL } from 'firebase/storage';
import '../../styles/ScansPage.css'; // Import the CSS file

const ScansPage = () => {
  const [scans, setScans] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchScans = async () => {
      if (auth.currentUser) {
        const userDoc = collection(firestore, 'users', auth.currentUser.uid, 'scans');
        const scansQuery = query(userDoc, orderBy('created_at', 'desc'));
        const scanSnapshots = await getDocs(scansQuery);

        const scansList = await Promise.all(
          scanSnapshots.docs.map(async (doc) => {
            const scanData = doc.data();
            const repImageUrl = await getDownloadURL(ref(storage, scanData.repImageUrl));
            return {
              id: doc.id,
              ...scanData,
              repImageUrl,
            };
          })
        );

        setScans(scansList);
      }
    };

    fetchScans();
  }, []);

  const handleScanClick = (scan) => {
    navigate('/results', { state: { data: scan } });
  };

  return (
    <div className="scans-page" style={{ overflowX: 'auto' }}>
      <h1 className="scans-title">Your Scans</h1>
      <ul className="scans-list">
        {scans.map(scan => (
          <li 
            key={scan.id} 
            onClick={() => handleScanClick(scan)} 
            className="scan-item"
          >
            <div className="scan-image-container">
              <img 
                src={scan.repImageUrl} 
                alt={`Scan ${scan.scan_number}`} 
                className="scan-image"
              />
            </div>
            <div className="scan-details">
              <p>Scan {scan.scan_number}</p>
              <p>{new Date(scan.created_at.seconds * 1000).toLocaleString()}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ScansPage;
