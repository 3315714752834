import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

// Register the required components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  annotationPlugin
);

const BellCurve = ({ score }) => {
  const scoreIndex = Math.round(score);

  const data = {
    labels: Array.from({ length: 100 }, (_, i) => i + 1),
    datasets: [
      {
        label: 'Bell Curve',
        data: Array.from({ length: 100 }, (_, i) =>
          (1 / (Math.sqrt(2 * Math.PI) * 10)) * Math.exp(-0.5 * Math.pow((i - 50) / 10, 2))
        ),
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
        backgroundColor: (ctx) => {
          const chart = ctx.chart;
          const { ctx: context, chartArea } = chart;
          if (!chartArea) {
            return null;
          }
          const gradient = context.createLinearGradient(0, 0, chartArea.width, 0);
          gradient.addColorStop(0, 'rgba(75, 192, 192, 0.2)');
          gradient.addColorStop(scoreIndex / 100, 'rgba(75, 192, 192, 0.2)');
          gradient.addColorStop(scoreIndex / 100, 'rgba(75, 192, 192, 0)');
          gradient.addColorStop(1, 'rgba(75, 192, 192, 0)');
          return gradient;
        },
        fill: 'start',
        pointRadius: 0,
      },
      {
        label: 'Red Line',
        data: Array.from({ length: 100 }, (_, i) => (i === scoreIndex ? 0.04 : null)),
        borderColor: 'red',
        borderWidth: 2,
        fill: false,
        pointRadius: 0,
        showLine: true,
        stepped: true,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: () => 'You are here',
        },
      },
      annotation: {
        annotations: {
          line1: {
            type: 'line',
            xMin: scoreIndex,
            xMax: scoreIndex,
            borderColor: 'red',
            borderWidth: 2,
          },
          label1: {
            type: 'label',
            xValue: scoreIndex,
            yValue: 0.04,
            backgroundColor: 'transparent',
            content: ['You', 'Are', 'Here'],
            font: {
              size: 12
            },
            color: 'red',
            textAlign: 'center',
            enabled: true,
            position: 'start',
            xAdjust: -38, // Adjust the position to the left
          },
        },
      },
    },
  };

  return (
    <div style={{ width: '100%', height: 'auto', marginTop: '10px' }}>
      <Line data={data} options={options} />
    </div>
  );
};

export default BellCurve;
